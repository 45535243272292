import { useNavigate, useSearchParams } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";
import { useCompany } from "@/lib";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";
import { useCreateGroup } from "@/hooks";

export const Footer = ({
  disableForward,
  isDynamicGroup,
  name,
  description,
}: {
  disableForward: boolean;
  isDynamicGroup: boolean;
  name: string;
  description?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { companyId } = useCompany();
  const { createGroupAsync, isLoading } = useCreateGroup();

  const handleSubmit = async () => {
    if (isDynamicGroup) {
      const res = await createGroupAsync({
        companyId,
        name,
        type: "DYNAMIC",
        ...(description && { description }),
      });
      navigate({ pathname: routes.pageDynamicGroup(res.id) });
    } else {
      const res = await createGroupAsync({
        companyId,
        name,
        type: "STATIC",
        ...(description && { description }),
      });
      searchParams.set("groupName", res.name);
      navigate({
        pathname: routes.pageManageGroupMembers(res.id),
        search: searchParams.toString(),
      });
    }
  };

  return (
    <Container>
      <CancelButton
        variant="neutral"
        onClick={() => navigate(routes.pageGroups())}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          onClick={() => navigate(routes.pageGroups())}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          disabled={disableForward}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Continuar <Icons name="IconArrowRight" />
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
