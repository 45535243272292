import { useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useFlag, useFlagsStatus } from "@flash-tecnologia/feature-flags";
import {
  Breadcrumbs,
  Icons,
  LinkButton,
  Loader,
  Stepper,
} from "@flash-tecnologia/hros-web-ui-v2";
import { usePermissions } from "@flash-tecnologia/hros-web-utility";

import { routes } from "@/routes";

import { CompanyDataForm } from "./CompanyDataForm";
import {
  Container,
  LoadingWrapper,
  MainContainer,
  Row,
  TitleContainer,
  TitleText,
} from "./styled";

export const PageAddCompany = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { flagsReady, flagsError } = useFlagsStatus();
  const isCompanyCreationEnabled = !useFlag({
    flagName: "FLASH_OS_FORCE_LEGACY_COMPANY_CREATION_UX",
  });

  if (!flagsReady && !flagsError) {
    return (
      <LoadingWrapper>
        <Loader size="large" variant="primary" />
      </LoadingWrapper>
    );
  }

  const canCreateCompany = permissions?.isAdmin && isCompanyCreationEnabled;

  if (!canCreateCompany || !!flagsError) {
    return <Navigate to={routes.economicGroup} />;
  }

  return (
    <MainContainer>
      <Row>
        <Breadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={[
            <LinkButton
              variant="neutral"
              onClick={() =>
                navigate(routes.economicGroup, { state: { tab: 0 } })
              }
            >
              Configurações
            </LinkButton>,
            <LinkButton variant="neutral">
              Adicionar via plataforma
            </LinkButton>,
          ]}
        />
        <Stepper
          steps={["Dados da empresa", "Assinatura de declaração"]}
          activeStep={0}
        />
      </Row>
      <Container>
        <TitleContainer>
          <TitleText variant="headline6" children="Adicionar via plataforma" />
        </TitleContainer>

        <CompanyDataForm ref={ref} />
      </Container>
    </MainContainer>
  );
};
