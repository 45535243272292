import { trpc } from "@/api/client";

export const useGetCompanyRoles = (companyId: string) => {
  const {
    data: roles,
    isLoading,
    refetch,
  } = trpc.getCompanyRoles.useQuery({ companyId });
  return { roles, isLoading, refetch };
};
