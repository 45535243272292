import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmptyState,
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Table } from "@departments/components";
import { DEPARTMENTS_PAGE, DEPARTMENTS_PAGE_CREATE } from "@departments/events";
import { useGetDepartments, useGetDepartmentEmployees } from "@/hooks";
import { setEventTracking, usePageTracking } from "@utils";
import { routes } from "src/routes";
import { Header, Skeleton, Tabs } from "./components";
import { Content, StyledBreadcrumbs } from "./styled";
import { Department } from "bff/src/services/company-management.service";
import { useCompany } from "@/lib";

export const DepartmentsPage = () => {
  usePageTracking(DEPARTMENTS_PAGE);
  const navigate = useNavigate();
  const [selectedDepartment, setSelectedDepartment] = useState<Department>(
    undefined as any,
  );
  const isFirstRender = useRef(true);
  const { companyId } = useCompany();

  const { data: departments, isLoading } = useGetDepartments(companyId);
  const {
    data: members,
    refetch: getMembers,
    isLoading: isGetMembersLoading,
  } = useGetDepartmentEmployees(selectedDepartment?.id);

  const isEmpty = !isLoading && !departments?.length;
  const hasDepartments = useMemo(
    () => !isLoading && !isEmpty && !!departments?.length,
    [isLoading, isEmpty, departments],
  );

  useEffect(() => {
    if (isFirstRender.current && departments?.length) {
      setSelectedDepartment(departments[0]);
      isFirstRender.current = false;
    }
  }, [departments]);

  useEffect(() => {
    if (selectedDepartment?.id) {
      getMembers();
    }
  }, [selectedDepartment?.id]);

  return (
    <PageContainer style={{ height: "100%" }}>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="neutral">Departamentos</LinkButton>,
        ]}
      />
      <Header departmentsQt={departments?.length || 0} />
      {isLoading && <Skeleton />}
      {isEmpty && (
        <EmptyState
          buttonText={
            <>
              <span>Criar novo departamento</span>
              <Icons name="IconPlus" />
            </>
          }
          description="Você ainda não criou nenhum departamento."
          buttonProps={{
            size: "medium",
            onClick: () => {
              navigate(routes.departmentCreate);
              setEventTracking(DEPARTMENTS_PAGE_CREATE, {
                departments_qt: departments?.length.toString() || "0",
              });
            },
          }}
        />
      )}
      {hasDepartments && (
        <Content>
          <Tabs
            departments={departments as any}
            selectedTab={selectedDepartment as any}
            setSelectedTab={setSelectedDepartment as any}
          />
          <Table
            loading={isGetMembersLoading}
            members={members as any}
            departmentId={selectedDepartment?.id || ""}
            departmentName={selectedDepartment?.name || ""}
          />
        </Content>
      )}
    </PageContainer>
  );
};
