import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useInsertEmployeeInGroup = () => {
  const utils = trpc.useContext();
  const { mutate, isLoading } = trpc.insertEmployeeInGroup.useMutation({
    onError: () => {
      dispatchToast({
        content: "Erro ao adicionar pessoas no grupo",
        type: "error",
      });
    },
    onSuccess: () => {
      utils.findEmployeeByGroupId.invalidate();
    },
  });

  return { insertEmployeeInGroup: mutate, isLoading };
};
