import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Icons,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes";

import { Container, StyledBreadcrumbs } from "./styled";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <IconButton
        variant={"line"}
        size={"small"}
        icon="IconArrowLeft"
        onClick={() => navigate(routes.pageGroups())}
      />
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageGroups())}
          >
            ...
          </LinkButton>,
          <LinkButton variant="neutral">Criar grupo</LinkButton>,
        ]}
      />
    </Container>
  );
};
