import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px 0px 0px;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  min-width: 312px;
  width: 20%;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 40px;
`;

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: var(--color-primary);
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: var(--color-neutral-50);
`;

export const StyledTypography = styled(Typography)``;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weight: 600;
`;

export const FormWrapper = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  max-width: 768px;
`;

export const StyledFormTitle = styled(Typography)`
  margin-bottom: 4px;
`;

export const StyledFormDescription = styled(Typography)`
  margin-bottom: 32px;
  color: var(--color-neutral-50);
`;

export const TextFieldInput = styled(TextField)`
  width: 100%;
`;

export const FieldSpacing = styled.div`
  padding: 16px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
