import { Container, Tab, Table, TableWrapper, Tabs } from "./styled";

export const Skeleton = () => {
  return (
    <Container>
      <Tabs>
        <Tab variant="rounded" />
        <Tab variant="rounded" />
        <Tab variant="rounded" />
        <Tab variant="rounded" />
        <Tab variant="rounded" />
        <Tab variant="rounded" />
      </Tabs>
      <TableWrapper>
        <Table variant="rounded" />
      </TableWrapper>
    </Container>
  );
};
