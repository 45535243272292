import { trpc } from "@/api/client";

export const useFindGroupById = (input: { groupId: string }) => {
  const {
    data,
    isLoading,
    refetch,
  } = trpc.findGroupById.useQuery(input);
  return { data, isLoading, refetch };
};
