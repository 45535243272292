import { Typography, Tag } from "@flash-tecnologia/hros-web-ui-v2";

import { Container } from "./styled";

export const TableHeader = ({ totalCount }: { totalCount: number }) => {
  return (
    <Container>
      <Typography variant="headline8">Pessoas neste grupo</Typography>
      <Tag as="span" variant={"gray"}>
        {`${totalCount} pessoas`}
      </Tag>
    </Container>
  );
};
