import { useEffect, useState } from "react";
import { Icons, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useMutation, useQuery } from "@apollo/client";
import { Divider } from "@Components/Divider";
import { FieldComponent } from "@Components/FieldComponent";
import { FieldPreview } from "@Components/FieldPreview";
import { ModalConfigureField } from "@Components/ModalConfigureField";
import { ModalConfirmAction } from "@Components/ModalConfirmAction";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { cloneDeep } from "lodash-es";
import { CreateSection } from "src/api/mutations/__generated__/CreateSection";
import { UpdateSection } from "src/api/mutations/__generated__/UpdateSection";
import { CREATE_SECTION } from "src/api/mutations/createSection";
import { DELETE_SECTION } from "src/api/mutations/deleteSection";
import { UPDATE_SECTION } from "src/api/mutations/updateSection";
import {
  GetCompanySectionsFields,
  GetCompanySectionsFields_getCompany_sectionsFields,
  GetCompanySectionsFields_getCompany_sectionsFields_fields,
} from "src/api/queries/__generated__/GetCompanySectionsFields";
import { GET_COMPANY_SECTIONS_FIELDS } from "src/api/queries/getCompanySectionsFields";
import { FieldComponentTypes, FieldTypeTypes } from "src/common/field";

import { SetSelectedSectionType } from "../Tabs";

import {
  CancelButton,
  ConfirmButton,
  Container,
  Content,
  ContentHeaderButtonsWrapper,
  ContentHeaderWrapper,
  DividerWrapper,
  ExistingFieldsActionsWrapper,
  ExistingFieldSelectWrapper,
  FieldComponentSkeleton,
  Header,
  OptionsWrapper,
  StyledButton,
  StyledDividerText,
  StyledOptionIcon,
  StyledPrimaryButton,
  StyledSubtitle,
  Title,
} from "./styled";

export const ContentCustomSection = ({
  name,
  fields,
  selectedSectionId,
  setSelectedCustomSection,
  customSections,
}: {
  name: string;
  selectedSectionId: string;
  fields: GetCompanySectionsFields_getCompany_sectionsFields_fields[];
  setSelectedCustomSection: SetSelectedSectionType;
  customSections: GetCompanySectionsFields_getCompany_sectionsFields[];
}) => {
  const { selectedCompany } = useSelectedCompany();
  const [modalConfigureFieldOpen, setModalConfigureFieldOpen] = useState(false);
  const [modalEditFieldOpen, setModalEditFieldOpen] = useState({
    isOpen: false,
    fieldId: "",
    name: "",
    component: undefined,
    sensitive: false,
    options: [],
  });
  const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
  const [deleteFieldModalOpen, setDeleteFieldModalOpen] = useState({
    isOpen: false,
    fieldId: "",
  });

  const [editingName, setEditingName] = useState({
    isEditing: false,
    value: name,
  });

  const [createSection] = useMutation<CreateSection>(CREATE_SECTION, {
    onCompleted: (res) => {
      dispatchToast({
        type: "success",
        content: "Seção criada com sucesso!",
      });
      setSelectedCustomSection(
        customSections.find(
          (section) => section._id === res.createSection?._id,
        ) as any,
      );
    },

    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao criar a seção. Por favor, tente novamente mais tarde.",
      });
    },
    refetchQueries: ["GetCompanySectionsFields"],
    awaitRefetchQueries: true,
  });

  const [updateSection, updateSectionRes] = useMutation<UpdateSection>(
    UPDATE_SECTION,
    {
      onCompleted: () => {
        setModalConfigureFieldOpen(false);
        setOpenSelectExistingField(false);
        setDeleteFieldModalOpen({ isOpen: false, fieldId: "" });
        setEditingName({ isEditing: false, value: editingName.value });
        setModalEditFieldOpen({
          isOpen: false,
          fieldId: "",
          name: "",
          component: undefined,
          sensitive: false,
          options: [],
        });

        dispatchToast({
          type: "success",
          content: "Seção modificada com sucesso!",
        });
      },
      onError: (error) => {
        const graphqlErrors: any = error.graphQLErrors?.[0];
        const errorCode = graphqlErrors?.errorCode as string;
        if (errorCode === "FIELD_CANNOT_BE_REMOVED_ERROR") {
          dispatchToast({
            type: "error",
            content:
              "Não é possível excluir campos que já foram preenchidos com dados de pessoas.",
          });
        } else {
          ErrorBoundary.captureException(error);
          dispatchToast({
            type: "error",
            content:
              "Ocorreu um erro ao modificar a seção. Por favor, tente novamente mais tarde.",
          });
        }
      },
      refetchQueries: ["GetCompanySectionsFields"],
      awaitRefetchQueries: true,
    },
  );

  const [deleteSection, deleteSectionRes] = useMutation(DELETE_SECTION, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Seção excluída com sucesso!",
      });
      setDeleteSectionModalOpen(false);
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;

      if (errorCode === "SECTION_CANNOT_BE_DELETED_ERROR") {
        dispatchToast({
          type: "error",
          content:
            "Não é possível excluir seções que contam com campos que já foram preenchidos com dados de pessoas.",
        });
      } else {
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao excluir a seção. Por favor, tente novamente mais tarde.",
        });
      }
    },
    refetchQueries: ["GetCompanySectionsFields"],
    awaitRefetchQueries: true,
  });

  const [openSelectExistingField, setOpenSelectExistingField] = useState(false);

  const { loading, data } = useQuery<GetCompanySectionsFields>(
    GET_COMPANY_SECTIONS_FIELDS,
    {
      variables: { companyId: selectedCompany.id },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, ocorreu um erro ao buscar as seções e campos. Por favor, tente novamente mais tarde.",
        });
      },
      fetchPolicy: "cache-first",
    },
  );

  const renderButton = () => {
    return (
      <StyledPrimaryButton
        size="large"
        variant="primary"
        onClick={() => setOpenSelectExistingField(true)}
      >
        <Icons name="IconLink" size={24} fill="transparent" />
        Adicionar campo existente
      </StyledPrimaryButton>
    );
  };

  const [selectedSection, setSelectedSection] =
    useState<GetCompanySectionsFields_getCompany_sectionsFields>();

  const [selectedFields, setSelectedFields] = useState<
    GetCompanySectionsFields_getCompany_sectionsFields_fields[]
  >([]);

  const renderSelectExistingField = () => {
    return (
      <ExistingFieldSelectWrapper>
        {loading ? (
          <>
            <FieldComponentSkeleton />
            <FieldComponentSkeleton />
          </>
        ) : (
          <>
            <FieldComponent
              name={"Selecione uma seção existente"}
              type={"array"}
              component={"select"}
              options={data?.getCompany?.sectionsFields?.map((item) => ({
                label: item?.name,
                value: item,
              }))}
              onChange={(value) => {
                setSelectedSection(value);
                setSelectedFields([]);
              }}
            />
            <FieldComponent
              value={selectedFields?.map((v) => ({ label: v?.name, value: v }))}
              name={"Selecione o campo"}
              type={"array"}
              component={"multiSelect"}
              disabled={!!!selectedSection}
              options={selectedSection?.fields?.map((item) => ({
                label: item?.name,
                value: item,
              }))}
              onChange={(value) => {
                setSelectedFields(value?.map((v: { value: any }) => v?.value));
              }}
            />
          </>
        )}
        <ExistingFieldsActionsWrapper>
          <CancelButton
            size="large"
            variant="secondary"
            onClick={() => {
              setSelectedSection(undefined);
              setSelectedFields(undefined as any);
              setOpenSelectExistingField(false);
              setSelectedFields([]);
            }}
          >
            Cancelar
          </CancelButton>
          <ConfirmButton
            size="large"
            variant="primary"
            disabled={!!!selectedFields?.length}
            onClick={() => {
              const handleValue = selectedFields?.map((item) => {
                const clonedItem = Object.assign({}, item);

                clonedItem.order = undefined as any;
                return clonedItem;
              });
              const newField = handleValue.map((e, index) => {
                return {
                  _id: e?._id,
                  name: e?.name,
                  sensitive: e?.sensitive,
                  order: index,
                  mask: e?.mask,
                  type: e?.type,
                  component: e?.component,
                  options: e?.options,
                  expires: e?.expires,
                };
              });

              const clonedFields = Object.assign([], fields);
              newField?.map((e: any) => clonedFields?.push(e));

              const data = clonedFields?.map((e: any, index) => {
                delete e.__typename;
                e.order = index;
                return e;
              });

              updateSection({
                variables: {
                  sectionId: selectedSectionId,

                  data: {
                    fields: data,
                  },
                },
              });
              setSelectedFields([]);
            }}
          >
            Copiar
          </ConfirmButton>
        </ExistingFieldsActionsWrapper>
      </ExistingFieldSelectWrapper>
    );
  };

  return (
    <>
      <Container>
        <Header>
          <Title variant="headline7">
            {editingName.isEditing ? (
              <TextField
                label={"Nome da seção"}
                placeholder={editingName.value}
                value={editingName.value}
                onChange={(e) =>
                  setEditingName((prevState) => ({
                    ...prevState,
                    value: e.target.value,
                  }))
                }
              />
            ) : (
              name
            )}
            <OptionsWrapper>
              <StyledOptionIcon
                name={editingName.isEditing ? "IconCheck" : "IconEdit"}
                color={
                  editingName.isEditing
                    ? "var(--color-feedback-success-dark1)"
                    : "var(--color-neutral-50)"
                }
                fill="transparent"
                size={24}
                onClick={() => {
                  if (editingName.isEditing) {
                    dispatchToast({
                      type: "warning",
                      content: "Renomeando seção...",
                    });
                    updateSection({
                      variables: {
                        sectionId: selectedSectionId,
                        data: {
                          name: editingName.value,
                        },
                      },
                    });
                  } else {
                    setEditingName({
                      value: name,
                      isEditing: true,
                    });
                  }
                }}
              />
              {editingName.isEditing && (
                <StyledOptionIcon
                  name={"IconX"}
                  color={"var(--color-tertiary-4)"}
                  fill="transparent"
                  size={24}
                  onClick={() => {
                    setEditingName({
                      value: name,
                      isEditing: false,
                    });
                  }}
                />
              )}
              <StyledOptionIcon
                name="IconCopy"
                fill="transparent"
                size={24}
                onClick={() => {
                  dispatchToast({
                    type: "warning",
                    content: "Copiando seção...",
                  });
                  createSection({
                    variables: {
                      name: `${name} (cópia)`,
                      companyId: selectedCompany.id,
                      fields: fields?.map((field, index) => ({
                        name: field?.name,
                        type: field?.type,
                        component: field?.component,
                        options: field?.options,
                        sensitive: field?.sensitive,
                        order: index,
                      })),
                    },
                  });
                }}
              />
              <StyledOptionIcon
                name="IconTrash"
                fill="transparent"
                size={24}
                onClick={() => setDeleteSectionModalOpen(true)}
              />
            </OptionsWrapper>
          </Title>
        </Header>
        <Content>
          <ContentHeaderWrapper>
            <StyledSubtitle variant="headline8">Campos</StyledSubtitle>
            <ContentHeaderButtonsWrapper>
              {openSelectExistingField && renderSelectExistingField()}
              {!openSelectExistingField && renderButton()}
              <DividerWrapper>
                <Divider horizontal />
                <StyledDividerText variant="body3">
                  Ou crie novos campos personalizados
                </StyledDividerText>
                <Divider horizontal />
              </DividerWrapper>
              <StyledButton
                size="large"
                variant="secondary"
                onClick={() => {
                  setModalConfigureFieldOpen(true);
                  setOpenSelectExistingField(false);
                  setSelectedFields([]);
                }}
              >
                <Icons name="IconPlus" fill="transparent" />
                Adicionar novo campo
              </StyledButton>
            </ContentHeaderButtonsWrapper>
          </ContentHeaderWrapper>
          {fields?.map((field, fieldIndex) => (
            <FieldPreview
              key={fieldIndex}
              options={{
                editCallback: () => {
                  setModalEditFieldOpen({
                    isOpen: true,
                    fieldId: field?._id,
                    name: field?.name,
                    component: field?.component,
                    options: field?.options,
                    sensitive: field?.sensitive,
                  } as any);
                },
                deleteCallback: () =>
                  setDeleteFieldModalOpen({
                    isOpen: true,
                    fieldId: field?._id,
                  }),
                copyCallback: () => {
                  const newFields = [...fields];
                  const newField = cloneDeep(
                    newFields?.find((f) => f?._id === field?._id),
                  ) as any;
                  delete newField?._id;
                  newFields?.unshift(newField);

                  updateSection({
                    variables: {
                      sectionId: selectedSectionId,
                      data: {
                        fields: newFields?.map((field: any, index) => {
                          delete field?.__typename;

                          return {
                            ...field,
                            order: index,
                          };
                        }),
                      },
                    },
                  });
                },
              }}
              field={{
                ...field,
                type: field?.type as FieldTypeTypes,
                component: field?.component as FieldComponentTypes,
                options: field?.options?.map((o) => ({
                  label: o,
                  value: o,
                })),
              }}
            />
          ))}
        </Content>
      </Container>
      <ModalConfigureField
        isOpen={modalConfigureFieldOpen}
        onClose={() => setModalConfigureFieldOpen(false)}
        loading={updateSectionRes?.loading}
        onSubmit={async (field) => {
          await updateSection({
            variables: {
              sectionId: selectedSectionId,
              data: {
                name: name,
                fields: [
                  ...(fields
                    ? fields.map((field: any, index) => {
                        delete field?.__typename;
                        field.order = index;
                        return field;
                      })
                    : []),
                  {
                    options: field?.options || [],
                    component: field?.component,
                    name: field?.name,
                    sensitive: field?.sensitive || false,
                    order: fields?.length,
                    mask: undefined,
                  },
                ],
              },
            },
          });
        }}
      />
      <ModalConfigureField
        isOpen={modalEditFieldOpen.isOpen}
        onClose={() =>
          setModalEditFieldOpen({
            isOpen: false,
            name: "",
            component: "Input",
            options: [],
            sensitive: false,
            fieldId: "",
          } as any)
        }
        loading={updateSectionRes?.loading}
        fieldData={{
          name: modalEditFieldOpen.name,
          component: modalEditFieldOpen.component as any,
          options: modalEditFieldOpen.options,
          sensitive: modalEditFieldOpen.sensitive,
        }}
        onSubmit={async (field) => {
          const newFields = cloneDeep(fields);
          const fieldIndex = newFields?.findIndex(
            (f) => f._id === modalEditFieldOpen.fieldId,
          );
          newFields[fieldIndex] = {
            ...newFields[fieldIndex],
            ...field,
          };
          dispatchToast({
            type: "warning",
            content: "Alterando campo...",
          });

          setSelectedCustomSection({
            ...(customSections.find((s) => s._id === selectedSectionId) as any),
            fields: newFields,
          });

          await updateSection({
            variables: {
              sectionId: selectedSectionId,
              data: {
                name: name,
                fields: newFields?.map((field: any, index) => {
                  delete field?.__typename;
                  delete field?.value;

                  return {
                    ...field,
                    order: index,
                  };
                }),
              },
            },
          }).then(() => {
            setSelectedCustomSection({
              ...(customSections.find(
                (s) => s._id === selectedSectionId,
              ) as any),
              fields: newFields,
            });
          });
        }}
      />
      <ModalConfirmAction
        isOpen={deleteSectionModalOpen}
        loading={deleteSectionRes?.loading}
        title="Tem certeza que deseja excluir esta seção?"
        description=""
        confirmWord="excluir"
        submitText={
          <>
            <span>Confirmar</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setDeleteSectionModalOpen(false)}
        onSubmit={async () => {
          dispatchToast({
            type: "warning",
            content: "Excluindo seção...",
          });
          deleteSection({
            variables: {
              sectionId: selectedSectionId,
            },
          });
        }}
      />
      <ModalConfirmAction
        isOpen={deleteFieldModalOpen.isOpen}
        loading={updateSectionRes?.loading}
        title="Tem certeza que deseja excluir este campo?"
        description=""
        submitText={
          <>
            <span>Confirmar</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setDeleteFieldModalOpen({ fieldId: "", isOpen: false })}
        onSubmit={async () => {
          dispatchToast({
            type: "warning",
            content: "Excluindo campo...",
          });
          updateSection({
            variables: {
              sectionId: selectedSectionId,
              data: {
                fields: [
                  ...fields?.filter(
                    (field) => field?._id !== deleteFieldModalOpen.fieldId,
                  ),
                ]?.map((field: any, index) => {
                  delete field?.__typename;
                  field.order = index;
                  return field;
                }),
              },
            },
          });
        }}
      />
    </>
  );
};
