import { useNavigate } from "react-router-dom";
import {
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { CompanyCard } from "@Components/CompanyCard";
import _ from "lodash";
import { routes } from "src/routes";

import { useGetCompany } from "@/features/companies/hooks";

import { CompanyData } from "./CompanyData";
import { StyledBreadcrumbs } from "./styled";

export const PageCompany = () => {
  const navigate = useNavigate();
  const { loading, company } = useGetCompany();

  return (
    <PageContainer>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 0 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="neutral">Informações da empresa</LinkButton>,
        ]}
      />
      <CompanyCard {..._.cloneDeep(company || {})} />
      <CompanyData {..._.cloneDeep(company || {})} />
    </PageContainer>
  );
};
