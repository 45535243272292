import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EmptyState,
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSession } from "@/common/user";
import { routes } from "src/routes";

import { Header } from "./components/Header";
import { Skeleton } from "./components/Skeleton";
import { Table } from "./components/Table";
import { Tabs } from "./components/Tabs";
import { Content, StyledBreadcrumbs } from "./styled";
import { useFindGroups } from "@/hooks";

export const PageGroups = () => {
  const { focusedGroup } = useParams();
  const { companyId } = useSession();
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    focusedGroup ? focusedGroup : "",
  );
  const navigate = useNavigate();

  const { groups: companyGroups, isLoading } = useFindGroups({ companyId });

  const groups = useMemo(() => {
    if (companyGroups?.length) {
      return companyGroups.map((g) => ({
        id: g.id,
        name: g.name,
        key: g.id,
        title: g.name,
        queryRule: g.queryRule,
        groupType: g.type,
      }));
    }
    return [];
  }, [companyGroups]);

  const selectedGroup = useMemo(
    () => groups?.find((g) => g.id === selectedGroupId) || groups?.[0],
    [groups, selectedGroupId],
  );

  return (
    <PageContainer style={{ height: "100%" }}>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="neutral">Grupos de pessoas</LinkButton>,
        ]}
      />
      <Header />
      {isLoading && <Skeleton />}
      {!isLoading && groups!?.length > 0 && (
        <Content>
          <Tabs
            tabs={groups}
            selectedTab={selectedGroup as any}
            setSelectedTab={(id) => setSelectedGroupId(id)}
          />
          <Table
            loading={isLoading}
            groupId={selectedGroup?.id!}
            groupName={selectedGroup?.name!}
            groupType={
              selectedGroup?.groupType ? selectedGroup.groupType : "STATIC"
            }
            queryRule={selectedGroup.queryRule}
          />
        </Content>
      )}
      {!isLoading && !groups?.length && (
        <EmptyState
          buttonText={"Criar novo grupo"}
          description={"Você ainda não criou nenhum grupo."}
          buttonProps={{
            size: "medium",
            onClick: () => navigate(routes.pageCreateGroup),
          }}
        />
      )}
    </PageContainer>
  );
};
