import { GroupListContainer } from "./styled";
import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

export interface IEmployeeGroupsProps {
  groups: { name: String }[] | [];
}

export const EmployeeGroups = ({ groups }: IEmployeeGroupsProps) => {
  const renderedGroups =
    (groups?.length || 0) > 3
      ? [
          <Tag variant="gray" as="span" key={1}>
            {groups?.[0].name}
          </Tag>,
          <Tag variant="gray" as="span" key={2}>
            {groups?.[1].name}
          </Tag>,
          <Tag variant="gray" as="span" key={3}>
            {`+${groups.length - 2}`}
          </Tag>,
        ]
      : groups?.map(({ name }, index) => (
          <Tag variant="gray" as="span" key={index}>
            {name}
          </Tag>
        ));

  return <GroupListContainer>{renderedGroups}</GroupListContainer>;
};
