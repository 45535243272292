import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";
import { useCallback } from "react";

export const useUpdateDepartment = () => {
  const { isLoading, mutate, data } = trpc.updateDepartment.useMutation();
  const updateDepartment = useCallback(
    (input: {
      departmentId: string;
      name?: string;
      externalId?: string;
      description?: string;
    }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Erro ao atualizar departamento.",
            type: "error",
          });
        },
      });
    },
    [mutate],
  );

  return { updateDepartment, isLoading, updatedDepartment: data };
};
