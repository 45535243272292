import { StatusEnum } from "@/common/StatusEnum";
import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

export const StatusTag = ({ status }: { status: StatusEnum | string }) => {
  switch (status) {
    case StatusEnum.ACTIVE:
    case "active":
      return (
        <Tag variant="success" as="span" hasLeftDotIcon={true}>
          Ativo
        </Tag>
      );

    case StatusEnum.INACTIVE:
    case "inactive":
      return (
        <Tag variant="gray" as="span" hasLeftDotIcon={true}>
          Inativo
        </Tag>
      );

    case StatusEnum.INVITATION_SCHEDULED:
    case "inviteScheduled":
      return (
        <Tag variant="blue" as="span" hasLeftDotIcon={true}>
          Convite agendado
        </Tag>
      );

    case StatusEnum.INVITATION_SENT:
    case "inviteSent":
      return (
        <Tag variant="blue" as="span" hasLeftDotIcon={true}>
          Convite enviado
        </Tag>
      );

    case StatusEnum.INVITATION_EXPIRED:
    case "inviteExpired":
      return (
        <Tag variant="yellow" as="span" hasLeftDotIcon={true}>
          Convite expirado
        </Tag>
      );

    case StatusEnum.INVITATION_PENDING:
    case "invitePending":
      return (
        <Tag variant="blue" as="span" hasLeftDotIcon={true}>
          Aguardando convite
        </Tag>
      );

    default:
      return <></>;
  }
};
