import { Container } from "./styled";
import { BaseTabCardType, TabCard } from "./TabCard";

export interface TabsInterface {
  tabs: BaseTabCardType[];
  selectedTab: BaseTabCardType;
  setSelectedTab: (id: string) => void;
}

export const Tabs = ({ tabs, selectedTab, setSelectedTab }: TabsInterface) => {
  return (
    <Container>
      {tabs?.map(({ key, title, queryRule, groupType }) => {
        const isSelected = key === selectedTab?.key;

        return (
          <TabCard
            key={key}
            id={key}
            title={title}
            isSelected={isSelected}
            setSelectedTab={(key) => setSelectedTab(key)}
            queryRule={queryRule}
            groupType={groupType}
          />
        );
      })}
    </Container>
  );
};
