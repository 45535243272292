import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Container } from "./styled";

export const Header = () => {
  return (
    <Container>
      <Typography variant="headline6">Gerenciar empresa</Typography>
    </Container>
  );
};
