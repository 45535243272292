import { FieldPreview } from "@Components/FieldPreview";
import { GetCompanySectionsFields_getCompany_sectionsFields_fields } from "src/api/queries/__generated__/GetCompanySectionsFields";
import { FieldComponentTypes, FieldTypeTypes } from "src/common/field";

import {
  Container,
  Content,
  ContentHeaderWrapper,
  Header,
  StyledSubtitle,
  Title,
} from "./styled";
import { Tag } from "@flash-tecnologia/hros-web-ui-v2";

export const ContentDefaultSection = ({
  name,
  fields,
}: {
  name: string;
  fields: GetCompanySectionsFields_getCompany_sectionsFields_fields[];
}) => {
  return (
    <Container>
      <Header>
        <Title variant="headline7">
          {name}
          <Tag
            variant="info"
            as="span"
            style={{
              margin: "auto 0",
              minWidth: "max-content",
              maxHeight: "26px",
            }}
          >
            Seção padrão
          </Tag>
        </Title>
      </Header>
      <Content>
        <ContentHeaderWrapper>
          <StyledSubtitle variant="headline8">Campos</StyledSubtitle>
        </ContentHeaderWrapper>
        {fields?.map((field) => (
          <FieldPreview
            key={field?._id}
            field={{
              ...field,
              type: field?.type as FieldTypeTypes,
              component: field?.component as FieldComponentTypes,
              options: field?.options?.map((o) => ({
                label: o,
                value: o,
              })),
            }}
          />
        ))}
      </Content>
    </Container>
  );
};
