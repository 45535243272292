import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { ModalConfirmSkip } from "@components/ModalConfirmSkip";
import { RoleFormStep } from "@features/roles/types";

import {
  CancelButton,
  Container,
  FirstWrapper,
  SecondWrapper,
  SkipButton,
} from "./styles";

interface FooterProps {
  loading?: boolean;
  submitButtonDisabled?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
  onBack?: () => void;
  onSkip?: () => void;
  modalSkip?: {
    title: string;
    description: string;
    submitButtonContent: ReactNode;
  };
  showSkipButton?: boolean;
  backButtonDisabled?: boolean;
  submitButtonContent: ReactNode;
}

export const Footer = ({
  loading,
  submitButtonDisabled,
  onSubmit,
  onCancel,
  onSkip,
  modalSkip,
  showSkipButton,
  backButtonDisabled,
  submitButtonContent,
  onBack,
}: FooterProps) => {
  const [skipModalIsOpen, setSkipModalIsOpen] = useState(false);

  return (
    <Container>
      <FirstWrapper>
        {onCancel && (
          <CancelButton variant="neutral" onClick={onCancel}>
            Cancelar
          </CancelButton>
        )}
        {onSkip && showSkipButton && (
          <SkipButton
            variant="neutral"
            onClick={() => {
              if (modalSkip) {
                setSkipModalIsOpen(true);
              } else {
                onSkip();
              }
            }}
          >
            Pular e finalizar
          </SkipButton>
        )}
      </FirstWrapper>

      <SecondWrapper>
        {onBack && (
          <Button
            size="large"
            variant="secondary"
            disabled={backButtonDisabled}
            onClick={onBack}
          >
            <Icons name="IconArrowLeft" />
            Voltar
          </Button>
        )}
        <Button
          size="large"
          variant="primary"
          loading={loading}
          disabled={submitButtonDisabled}
          onClick={onSubmit}
        >
          {submitButtonContent}
        </Button>
      </SecondWrapper>

      {onSkip && modalSkip && (
        <ModalConfirmSkip
          isOpen={skipModalIsOpen}
          loading={loading as any}
          title={modalSkip.title}
          description={modalSkip.description}
          submitText={modalSkip.submitButtonContent}
          onClose={() => {
            setSkipModalIsOpen(false);
          }}
          onSubmit={() => {
            setSkipModalIsOpen(false);
            onSkip();
          }}
        />
      )}
    </Container>
  );
};
