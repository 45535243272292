import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useDeleteGroup = () => {
  const utils = trpc.useContext();
  const { mutate, isLoading } = trpc.deleteGroup.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: "Grupo deletado com sucesso!",
      });
      utils.findGroups.invalidate();
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: "Erro ao deletar grupo.",
      });
    },
  });

  return { deleteGroup: mutate, isLoading };
};
