import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTransferList } from "../../Root";
import Search from "./search";
import { HeaderStyled, TitleStyled } from "./styled";

function Title({ count }: { count: number }) {
  return (
    <>
      <TitleStyled>
        <Typography variant="headline8">Selecionados</Typography>
        <Tag variant="gray" as="span">
          {count}
        </Tag>
      </TitleStyled>
    </>
  );
}

export default function Header() {
  const { selected } = useTransferList();

  return (
    <>
      <HeaderStyled>
        <Title count={selected?.length || 0} />
        <Search />
      </HeaderStyled>
    </>
  );
}
