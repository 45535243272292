import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  EmptyState,
  Icons,
  LinkButton,
  MenuOptionType,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { GetRoles_roles } from "@/api/queries/roles/__generated__/GetRoles";
import { FilterStatusEnum } from "@/common/StatusEnum";
import { arrayToTabItems, ModalConfirmAction, SidebarTabs } from "@/components";
import { PageWrapper } from "@/components/page-wrapper";
import { Table } from "@/features/roles/components";
import { useDeleteRole, useGetRoles } from "@/features/roles/hooks";
import { routes } from "@/routes";
import { ResultState, usePageTracking } from "@/utils";

import {
  RoleTableProvider,
  useRolesTableContext,
} from "../../components/table/table-context";
import { useGetRoleMembersPaginated } from "../../hooks/use-get-role-members";
import { ROLES_LIST_PAGE } from "../../types/events";

import { Skeleton } from "./components/skeleton";
import {
  Content,
  HeaderContainer,
  HeaderWrapper,
  Option,
  OptionSpan,
  StyledBreadcrumbs,
} from "./styles";

const RolesListContent = () => {
  usePageTracking(ROLES_LIST_PAGE);
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState<GetRoles_roles>();
  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
  const isFirstRender = useRef(true);

  const { roles, state: queryState } = useGetRoles();
  const {
    getRoleMembers,
    members: roleMembers,
    data,
    loading: isRoleMembersLoading,
  } = useGetRoleMembersPaginated();
  const { deleteRole, loading } = useDeleteRole();

  const {
    state: { paginationState, searchValue },
    dispatch,
  } = useRolesTableContext();

  const isLoading =
    queryState === ResultState.LOADING || queryState === ResultState.IDLE;
  const isEmpty = !isLoading && !roles.length;
  const hasRoles = useMemo(
    () => !isLoading && !isEmpty && !!roles.length,
    [isLoading, isEmpty, roles],
  );

  const tabItems = useMemo(
    () => arrayToTabItems(roles, { key: "id", tabName: "name" }, selectedRole),
    [roles, selectedRole],
  );

  const options = useMemo<MenuOptionType[]>(
    () => [
      {
        selected: false,
        children: (
          <Option
            onClick={() =>
              navigate({
                pathname: routes.roleEmployees(selectedRole?.id as any),
                search: "?from=/roles",
              })
            }
          >
            <Icons name="IconClick" fill="transparent" />
            Atribuir integrantes por plataforma
          </Option>
        ),
      },
      {
        selected: false,
        children: (
          <Option onClick={() => navigate(routes.roleForm(selectedRole?.id))}>
            <Icons name="IconPencil" fill="transparent" />
            Editar cargo
          </Option>
        ),
      },
      {
        selected: false,
        children: (
          <Option onClick={() => setDeactivateModalIsOpen(true)}>
            <Icons
              name="IconTrash"
              fill="transparent"
              color="var(--color-feedback-error-dark1)"
            />
            <OptionSpan>Excluir cargo</OptionSpan>
          </Option>
        ),
      },
    ],
    [selectedRole?.id],
  );

  useEffect(() => {
    if (isFirstRender.current && roles.length) {
      setSelectedRole(roles[0]);
      isFirstRender.current = false;
    }
  }, [roles]);

  useEffect(() => {
    if (selectedRole?.id) {
      getRoleMembers({
        roleId: selectedRole.id,
        page: paginationState.pageNumber,
        limit: paginationState.pageSize,
        status: FilterStatusEnum.REMOVE_INACTIVE,
        search: searchValue || undefined,
      });
    }
  }, [
    selectedRole?.id,
    paginationState.pageNumber,
    paginationState.pageSize,
    searchValue,
  ]);

  useEffect(() => {
    dispatch({
      type: "set_pagination_state",
      payload: {
        totalCount: data?.recordsMetadata?.totalCount,
        totalPages: data?.recordsMetadata?.totalPages,
      },
    });
  }, [
    data?.recordsMetadata?.totalPages,
    data?.recordsMetadata?.totalCount,
    dispatch,
  ]);

  const handleModalSubmit = () => {
    deleteRole({
      roleId: selectedRole?.id,
    } as any);
    setSelectedRole(
      roles.filter((r) => r.id !== selectedRole?.id).at(0) as any,
    );
    setDeactivateModalIsOpen(false);
  };

  return (
    <PageWrapper
      header={
        <>
          <StyledBreadcrumbs
            separator={<Icons name="IconChevronRight" fill="transparent" />}
            breadcrumbs={[
              <LinkButton
                key="company"
                variant="neutral"
                onClick={() =>
                  navigate(routes.pageInitial, { state: { tab: 1 } })
                }
              >
                Gerenciar empresa
              </LinkButton>,
              <LinkButton key="roles" variant="neutral">
                Cargos
              </LinkButton>,
            ]}
          />
          <HeaderContainer>
            <HeaderWrapper>
              <Typography variant="headline6">Cargos</Typography>
              <Typography variant="body4">
                Crie e gerencie cargos e seus integrantes.
              </Typography>
            </HeaderWrapper>
            <span>
              <Button
                size="large"
                variant="primary"
                onClick={() => {
                  navigate(routes.roleForm());
                }}
              >
                Criar novo cargo
                <Icons name="IconPlus" size={14} />
              </Button>
            </span>
          </HeaderContainer>
        </>
      }
    >
      {isLoading && <Skeleton />}
      {isEmpty && (
        <EmptyState
          buttonText={
            <>
              <span>Criar novo cargo</span>
              <Icons name="IconPlus" />
            </>
          }
          description="Você ainda não criou nenhum cargo."
          buttonProps={{
            size: "medium",
            onClick: () => {
              navigate(routes.roleForm());
            },
          }}
        />
      )}
      {hasRoles && (
        <Content>
          <SidebarTabs<typeof tabItems[0]>
            items={tabItems}
            tabMenuOptions={options}
            onTabClick={(item) => {
              setSelectedRole(item);
            }}
            modal={
              <ModalConfirmAction
                isOpen={deactivateModalIsOpen}
                loading={loading}
                title="Tem certeza que deseja excluir este cargo?"
                description="As pessoas deste cargo ficarão sem atribuição e todas as configurações realizadas serão perdidas."
                confirmWord="excluir"
                submitText={
                  <>
                    <span>Confirmar exclusão</span>
                    <Icons name="IconTrash" size={30} fill="transparent" />
                  </>
                }
                onClose={() => setDeactivateModalIsOpen(false)}
                onSubmit={handleModalSubmit}
              />
            }
          />
          <Table
            loading={isRoleMembersLoading}
            data={roleMembers}
            roleId={selectedRole?.id || ""}
            pagination
          />
        </Content>
      )}
    </PageWrapper>
  );
};

export const RolesListPage = () => (
  <RoleTableProvider>
    <RolesListContent />
  </RoleTableProvider>
);
