import { ReactNode } from "react";
import { DEPARTMENT_CREATE_SKIP_MODAL_BACK } from "@departments/events";
import { setEventTracking } from "@Utils/eventTracking";

import {
  FooterCancelButton,
  FooterContainer,
  SubmitButton,
  SubmitButtonText,
} from "./styled";

interface FooterProps {
  onClose: () => void;
  onSubmit: () => void;
  submitText: ReactNode;
  loading: boolean;
}

export const Footer = ({
  onClose,
  onSubmit,
  submitText,
  loading,
}: FooterProps) => {
  return (
    <FooterContainer>
      <FooterCancelButton
        onClick={() => {
          onClose();
          setEventTracking(DEPARTMENT_CREATE_SKIP_MODAL_BACK);
        }}
      >
        Voltar e adicionar
      </FooterCancelButton>
      <SubmitButton
        variant={"secondary"}
        variantType={"default"}
        buttonType={"primary"}
        size={"medium"}
        style={{ margin: "auto 0", padding: "19px 52px" }}
        onClick={onSubmit}
        loading={loading}
        disableRipple
        disableTouchRipple
      >
        <SubmitButtonText>{submitText}</SubmitButtonText>
      </SubmitButton>
    </FooterContainer>
  );
};
