import { useNavigate } from "react-router-dom";
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DEPARTMENTS_HEADER_CREATE } from "@departments/events";
import { setEventTracking } from "@Utils/eventTracking";
import { routes } from "src/routes";

import { BoldText, Container, Wrapper } from "./styled";

interface HeaderProps {
  departmentsQt: number;
}
export const Header = ({ departmentsQt }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <Typography variant="headline6">Departamentos</Typography>
        <Typography variant="body4">
          Um departamento é um agrupamento de pessoas e pode representar grandes
          áreas (Ex: Tecnologia, Jurídico, Financeiro) ou subáreas (Ex: Design,
          Desenvolvimento, Marketing){" "}
          <BoldText>
            Os departamentos serão utilizados como informação para diversos
            lugares dentro da plataforma.
          </BoldText>
        </Typography>
      </Wrapper>
      <span>
        <Button
          size="large"
          variant="primary"
          onClick={() => {
            setEventTracking(DEPARTMENTS_HEADER_CREATE, {
              departments_qt: departmentsQt.toString(),
            });
            navigate(routes.departmentCreate);
          }}
        >
          Criar novo departamento
          <Icons name="IconPlus" size={14} />
        </Button>
      </span>
    </Container>
  );
};
