import { useNavigate } from "react-router-dom";
import {
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useGetEconomicGroupCompanies } from "@features/economicGroup/hooks";
import { routes } from "src/routes";

import { Header } from "./components/Header";
import { Table } from "./components/Table";
import { StyledBreadcrumbs } from "./styled";

export const EconomicGroupPage = () => {
  const navigate = useNavigate();
  const { companies, isLoading } = useGetEconomicGroupCompanies();

  return (
    <PageContainer style={{ height: "100%" }}>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 0 } })}
          >
            Configurações
          </LinkButton>,
          <LinkButton variant="neutral">Empresas do grupo</LinkButton>,
        ]}
      />
      <Header />
      <Table data={companies} loading={isLoading} />
    </PageContainer>
  );
};
