import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";

import { useDepartmentsTableContext } from "../table-context";

import { Container } from "./styled";

interface TableFiltersProps {
  disabled: boolean;
  departmentId: string;
  departmentName: string;
}

export const TableFilters = ({ disabled, departmentId }: TableFiltersProps) => {
  const navigate = useNavigate();

  const { dispatch } = useDepartmentsTableContext();

  useEffect(() => {
    dispatch({ type: "clear_search_value" });
  }, [departmentId]);

  return (
    <Container>
      <Button
        variant="secondary"
        size="small"
        disabled={disabled}
        onClick={() => navigate(routes.departmentEmployeesEdit(departmentId))}
      >
        Atribuir integrantes
      </Button>
    </Container>
  );
};
