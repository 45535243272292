import { trpc } from "@/api/client";

export const useFindGroups = (input: { companyId: string }) => {
  const {
    data: groups,
    isLoading,
    refetch,
  } = trpc.findGroups.useQuery(input);
  return { groups, isLoading, refetch };
};
