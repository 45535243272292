import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  min-width: 312px;
  width: 20%;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 40px;
`;

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: var(--color-primary);
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: var(--color-neutral-50);
`;

export const StyledTypography = styled(Typography)``;

export const TransferListArea = styled.div`
  width: 100%;
  max-width: 100%;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EmailIcon = styled(Icons)`
  color: var(--color-neutral-70);
`;

export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
export const EmailText = styled(Typography)`
  color: var(--color-neutral-60);
`;

export const FirstColumnCell = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstColumnCellData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10px;
`;
