import { trpc } from "@/api/client";

export const useFindEmployeesByGroupId = (input: {
  groupId: string;
  limit: number;
  page: number;
}) => {
  const { data, isLoading, refetch } =
    trpc.findEmployeeByGroupId.useQuery(input);
  return { data, isLoading, refetch };
};
