import { useEffect, useMemo, useState } from "react";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  AddFilterButton,
  Body,
  Container,
  ContentWrapper,
  Divider,
  FormContainer,
  FormWrapper,
  StyledDescription,
  StyledFormDescription,
  StyledFormTitle,
  StyledSubtitle,
  StyledTitle,
  TableContainer,
  TextWrapper,
} from "./styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFindGroupById,
  useGetCompanyRoles,
  useGetDepartments,
  useUpdateGroup,
  useEmployeeSearch,
} from "@/hooks";
import { useCompany } from "@/lib";
import { TableGrid } from "./components/DataGrid";
import { Employee } from "bff/src/services/company-management.service";
import { Filter, FilterProps } from "./components/Filter";
import { routes } from "@/routes";

export type Rule = {
  field: "departments" | "roles" | undefined;
  operator: "IN" | "NOTIN" | undefined;
  value: string[];
};

type Option = {
  label: string;
  value: string;
};

const ActionOptions = [
  { label: "Incluir", value: "IN" },
  { label: "Excluir", value: "NOTIN" },
];

const FieldOptions = [
  { label: "Cargo", value: "roles" },
  { label: "Departamento", value: "departments" },
];

const buildQueryRule = (
  rules: Rule[],
):
  | {
      combinator: "AND" | "OR";
      rules: { field: string; operator: string; value: string[] }[];
    }
  | undefined => {
  const someValueIsEmpty = rules.some(
    (r) => !(r.value?.length && r.field && r.operator),
  );
  if (!someValueIsEmpty)
    return {
      combinator: "AND",
      rules,
    } as any;
};

const createNewRule = (): Rule => ({
  field: undefined,
  operator: undefined,
  value: [],
});

const renderFilter = ({
  actionOptions,
  disableValueFilter,
  fieldOptions,
  handleSelect,
  index,
  loading,
  onDeleteButtonClick,
  renderDeleteButton,
  valueOptions,
  rule,
}: FilterProps) => {
  return (
    <>
      {renderDeleteButton && <Divider />}
      <Filter
        index={index}
        actionOptions={actionOptions}
        fieldOptions={fieldOptions}
        valueOptions={valueOptions}
        handleSelect={handleSelect}
        onDeleteButtonClick={onDeleteButtonClick}
        disableValueFilter={disableValueFilter}
        loading={loading}
        renderDeleteButton={renderDeleteButton}
        rule={rule}
      />
    </>
  );
};

export const PageDynamicGroup = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { companyId } = useCompany();
  const { data: group, isLoading: isGetGroupLoading } = useFindGroupById({
    groupId: groupId!,
  });
  const { data: departments, isLoading: isGetDepartmentsLoading } =
    useGetDepartments(companyId!);
  const { roles, isLoading: isGetCompanyRolesLoading } = useGetCompanyRoles(
    companyId!,
  );
  const {
    data: employeeSearchResult,
    isLoading: isGetEmployeesLoading,
    fetch: employeeSearch,
  } = useEmployeeSearch();
  const { updateGroupAsync, isLoading: isUpdateGroupLoading } =
    useUpdateGroup();
  const [name, setName] = useState("");

  const [members, setMembers] = useState<Partial<Employee>[]>([]);
  const [rules, setRules] = useState<Rule[]>([]);

  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[][]>([]);
  const [options, setOptions] = useState<Option[][]>([]);

  useEffect(() => {
    setName(group?.name!);
    const queryRule = group?.queryRule;
    if (queryRule) {
      const { rules } = queryRule;
      const setFields: string[] = [];
      const setActions: string[] = [];
      const setValues: string[][] = [];
      rules.forEach((r) => {
        setFields.push(r.field);
        setActions.push(r.operator);
        setValues.push(r.value);
      });
      setRules([...queryRule.rules]);
      setSelectedActions(setActions);
      setSelectedValues(setValues);
      setSelectedFields(setFields);
    } else {
      setRules([createNewRule()]);
    }
  }, [group]);

  useEffect(() => {
    const queryRule = buildQueryRule(rules);
    if (!queryRule) return;
    employeeSearch({ queryRule: queryRule as any });
  }, [JSON.stringify(rules)]);

  const departmentOptions = useMemo(() => {
    const options = departments?.map((d) => ({
      label: d.name,
      value: d.id,
    }));
    return options || [];
  }, [departments]);

  const roleOptions = useMemo(() => {
    const options = roles?.map((r) => ({
      label: r.name,
      value: r.id,
    }));
    return options || [];
  }, [roles]);

  useEffect(() => {
    setMembers(employeeSearchResult?.employees || []);
  }, [employeeSearchResult]);

  useEffect(() => {
    const optionsToSet = selectedFields.map((field) => {
      if (field === "departments") {
        return departmentOptions;
      } else if (field === "roles") {
        return roleOptions;
      }
    });
    if (optionsToSet?.length) setOptions(optionsToSet as any);
  }, [JSON.stringify(selectedFields), departmentOptions, roleOptions]);

  const shouldRenderFilter = (rules: any[]) => {
    const allOptionsLoaded =
      !isGetCompanyRolesLoading && !isGetDepartmentsLoading;
    return rules.length > 0 && allOptionsLoaded;
  };

  const onDeleteButtonClick = (index: number) => {
    const updateOptions = [...options];
    updateOptions.splice(index, 1);

    const updateRules = [...rules];
    updateRules.splice(index, 1);

    const updateActions = [...selectedActions];
    updateActions.splice(index, 1);

    const updateFields = [...selectedFields];
    updateFields.splice(index, 1);

    const updateValues = [...selectedValues];
    updateValues.splice(index, 1);

    setSelectedActions(updateActions);
    setSelectedFields(updateFields);
    setSelectedValues(updateValues);
    setOptions(updateOptions);
    setRules(updateRules);
  };

  const handleForwardButtonClick = async () => {
    await updateGroupAsync({
      groupId: groupId!,
      queryRule: buildQueryRule(rules),
    });
    navigate({ pathname: routes.pageGroups() });
  };

  const handleSelect = (input: {
    rule: {
      field?: "departments" | "roles";
      operator?: "IN" | "NOTIN";
      value?: string[];
    };
    index: number;
  }) => {
    const keys = Object.keys(input.rule);
    const updateRules = [...rules];

    if (keys.includes("field")) {
      const updateFields = [...selectedFields];
      updateFields[input.index] = input.rule.field!;
      if (input.rule.field! !== selectedFields[input.index]) {
        const updateValues = [...selectedValues];
        updateValues[input.index] = [];
        setSelectedValues(updateValues);
        updateRules[input.index] = {
          ...updateRules[input.index],
          ...{ field: input.rule.field! as any, value: [] },
        };
      }
      setSelectedFields(updateFields);
    }

    if (keys.includes("operator")) {
      const updateActions = [...selectedActions];
      updateActions[input.index] = input.rule.operator!;
      setSelectedActions(updateActions);
    }

    if (keys.includes("value")) {
      const updateValues = [...selectedValues];
      updateValues[input.index] = input.rule.value!;
      if (!updateValues[input.index]?.[0]) setMembers([]);
      setSelectedValues(updateValues);
    }

    updateRules[input.index] = { ...updateRules[input.index], ...input.rule };
    setRules(updateRules);
  };

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <StyledTitle variant="headline6">Editar grupo</StyledTitle>
          <Container>
            <TextWrapper>
              <StyledSubtitle variant="headline8">
                Atribuir as pessoas ao grupo
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Configure os filtros que serão usados para selecionar
                integrantes do grupo <strong>{name}</strong>
              </StyledDescription>
            </TextWrapper>
            <FormContainer>
              <FormWrapper>
                <StyledFormTitle variant="headline8">
                  Atribuição dinâmica
                </StyledFormTitle>
                <StyledFormDescription variant="body4">
                  Selecione filtros com atributos de cadastro e associaremos
                  todas as pessoas que corresponderem à sua seleção. Sempre que
                  um cadastro for criado ou alterado, ele automaticamente será
                  associado ao grupo correspondente. Melhor opção para quem quer
                  praticidade e uma lista de pessoas sempre atualizada.
                </StyledFormDescription>
                {shouldRenderFilter(rules) &&
                  rules.map((r, index) => {
                    return renderFilter({
                      index: index,
                      fieldOptions: FieldOptions,
                      actionOptions: ActionOptions,
                      valueOptions: options?.[index] || [],
                      handleSelect,
                      onDeleteButtonClick,
                      renderDeleteButton: index > 0,
                      loading:
                        isGetGroupLoading ||
                        isGetDepartmentsLoading ||
                        isGetCompanyRolesLoading,
                      disableValueFilter: !r?.field,
                      rule: r,
                    });
                  })}
                <Divider />
                <AddFilterButton
                  variant="secondary"
                  loading={isGetGroupLoading}
                  onClick={() => setRules((prev) => [...prev, createNewRule()])}
                >
                  + Adicionar novo filtro
                </AddFilterButton>
              </FormWrapper>
              <FormWrapper>
                <StyledFormTitle variant="headline8">
                  Pré-visualização da seleção de pessoas
                </StyledFormTitle>
                <StyledFormDescription variant="body4">
                  Confira abaixo o resultado dessa seleção. Lembrando que essa
                  lista é dinâmica e pode mudar conforme os cadastros são
                  criados ou atualizados.
                </StyledFormDescription>
                <TableContainer>
                  <TableGrid
                    data={members || []}
                    isLoading={isGetEmployeesLoading}
                    pageSize={10}
                  />
                </TableContainer>
              </FormWrapper>
            </FormContainer>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        disableForward={!rules?.[0]?.value.length}
        handleForwardButtonClick={handleForwardButtonClick}
        loading={isUpdateGroupLoading}
      />
    </Body>
  );
};
