import { trpc } from "@/api/client";

export const useInsertEmployeeInDepartment = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate } = trpc.insertEmployeeInDepartment.useMutation({
    onSuccess: () => {
      utils.getDepartmentEmployees.invalidate();
    },
  });

  return { insertEmployeeInDepartment: mutate, isLoading };
};
