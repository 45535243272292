import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useDeactivateDepartment = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate } = trpc.deactivateDepartment.useMutation({
    onError: () => {
      dispatchToast({
        content: "Erro ao desativar departamento.",
        type: "error",
      });
    },
    onSuccess: () => {
      dispatchToast({
        content: "Departamento desativado com sucesso",
        type: "success",
      });
      utils.getDepartments.invalidate();
    },
  });

  return { deactivateDepartment: mutate, isLoading };
};
