import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icons, PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import {
  CreateTransferList,
  Footer,
  Header,
  HeaderBreadcrumbs,
} from "@departments/components";
import {
  DEPARTMENT_CREATE_BACK,
  DEPARTMENT_CREATE_CANCEL,
  DEPARTMENT_CREATE_SKIP,
} from "@departments/events";
import {
  useCreateDepartment,
  useGetDepartmentById,
  useUpdateDepartment,
  useGetDepartmentEmployees,
  useInsertEmployeeInDepartment,
  useRemoveEmployeeFromDepartment,
} from "@/hooks";
import { DepartmentFormStep } from "@departments/types";
import { setEventTracking } from "@utils/eventTracking";
import { routes } from "src/routes";
import { useSession } from "@/common/user";
import { Body, Container, ContentWrapper } from "./styled";
import { Employee } from "bff/src/services/company-management.service";

const EditDepartmentSubmitButton = () => (
  <>
    Editar departamento
    <Icons name="IconCheck" fill="transparent" />
  </>
);

const CreateDepartmentSubmitButton = () => (
  <>
    Criar departamento
    <Icons name="IconCheck" fill="transparent" />
  </>
);

export const DepartmentEmployeesPage = () => {
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const isEdit = !!departmentId;
  const mainTitle = `${isEdit ? "Editar" : "Criar"} departamento`;
  const breadcrumbs: HeaderBreadcrumbs[] = [
    {
      label: "Gerenciar empresa",
      route: routes.pageInitial,
      options: { state: { tab: 1 } },
    },
    {
      label: "...",
      route: routes.departments,
    },
    { label: mainTitle },
  ];

  const { companyId } = useSession();
  const [activeStep, setActiveStep] = useState(DepartmentFormStep.FIRST);
  const [employeesToInsert, setEmployeesToInsert] = useState<Employee[]>([]);

  const { data: department, isLoading: isGetEmployeesLoading } =
    useGetDepartmentById(departmentId as string);
  const { createDepartment, isLoading: isCreateDepartmentLoading } =
    useCreateDepartment();
  const { updateDepartment, isLoading: isUpdateDepartmentLoading } =
    useUpdateDepartment();
  const {
    data: departmentEmployees,
    refetch: getMembers,
    isLoading: isGetDepartmentEmployeesLoading,
  } = useGetDepartmentEmployees(departmentId!);
  const { insertEmployeeInDepartment } = useInsertEmployeeInDepartment();
  const { removeEmployeeFromDepartment } = useRemoveEmployeeFromDepartment();

  useEffect(() => {
    if (departmentId) {
      getMembers();
    }
  }, [departmentId]);

  useEffect(() => {
    if (departmentEmployees?.length) {
      setEmployeesToInsert(departmentEmployees as any);
    }
  }, [departmentEmployees]);

  const createOrUpdateDepartment = () => {
    if (isEdit) {
      updateDepartment({
        departmentId: department?.id!,
        name: department?.name,
        externalId: department?.externalId,
        description: department?.description,
      });
      employeesToInsert.forEach((e) => {
        insertEmployeeInDepartment({
          departmentId: department?.id!,
          employeeId: e.id,
        });
      });
      const employeesToRemove =
        departmentEmployees?.filter((e) => {
          return !employeesToInsert.some((selected) => selected.id === e.id);
        }) || [];

      if (employeesToRemove.length) {
        for (const e of employeesToRemove) {
          removeEmployeeFromDepartment({ departmentId, employeeId: e.id! });
        }
      }
    } else {
      createDepartment({
        name: department?.name!,
        description: department?.description || "",
        externalId: department?.externalId || "",
        companyId,
      });
    }
    navigate(routes.departments);
  };

  const handleSubmit = () => {
    createOrUpdateDepartment();
  };

  const handleCancel = () => {
    navigate(routes.departments);
    setEventTracking(DEPARTMENT_CREATE_CANCEL, {
      step: activeStep.toString(),
    });
  };

  const handleBack = () => {
    if (isEdit) {
      navigate(routes.departmentEdit(departmentId));
    } else {
      navigate(routes.departmentCreate);
    }
    setEventTracking(DEPARTMENT_CREATE_BACK, {
      step: activeStep.toString(),
    });
  };

  const handleSkip = () => {
    setEventTracking(DEPARTMENT_CREATE_SKIP, {
      step: activeStep.toString(),
    });
    createOrUpdateDepartment();
  };

  const SubmitButtonContent = () =>
    isEdit ? <EditDepartmentSubmitButton /> : <CreateDepartmentSubmitButton />;

  return (
    <Body>
      <Header
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        breadcrumbs={breadcrumbs}
      />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <CreateTransferList
              title={mainTitle}
              employeesToInsert={employeesToInsert}
              setEmployeesToInsert={setEmployeesToInsert}
            />
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        loading={
          isCreateDepartmentLoading ||
          isUpdateDepartmentLoading ||
          isGetDepartmentEmployeesLoading ||
          isGetEmployeesLoading
        }
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        onBack={handleBack}
        onSubmit={handleSubmit}
        onSkip={handleSkip}
        modalSkip={
          !isEdit
            ? {
                title:
                  "Você não adicionou niguém ao departamento. Deseja sair mesmo assim?",
                description:
                  "Você poderá adicionar pessoas a este departamento em outro momento.",
                submitButtonContent: <SubmitButtonContent />,
              }
            : undefined
        }
        onCancel={handleCancel}
        submitButtonContent={<SubmitButtonContent />}
      />
    </Body>
  );
};
