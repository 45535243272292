import { useNavigate } from "react-router-dom";
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes";

import { Container, Wrapper } from "./styled";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <Typography variant="headline6">Grupos de pessoas</Typography>
        <Typography variant="body4">
          Crie grupos de pessoas personalizados.
        </Typography>
      </Wrapper>
      <span>
        <Button
          size="large"
          variant="primary"
          onClick={() => navigate(routes.pageCreateGroup)}
        >
          Criar grupo <Icons name="IconPlus" size={24} />
        </Button>
      </span>
    </Container>
  );
};
