import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { ModalConfirmAction } from "@Components/index";
import dispatchToast from "@Utils/dispatchToast";
import { routes } from "src/routes";
import {
  useDeleteGroup,
} from "@/hooks";

import { Option, StyledIcon } from "./styled";
import { TOption } from "./types";

export const CardOptions = ({
  groupId,
  active,
  groupName,
}: {
  groupId: string;
  active: boolean;
  groupName: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
  const { deleteGroup, isLoading: deletionIsLoading } = useDeleteGroup();

  const options: TOption[] = useMemo(() => {
    return [
      {
        key: "edit",
        children: (
          <Option
            onClick={() => {
              searchParams.set("groupName", groupName);
              navigate({
                pathname: routes.pageEditGroup(groupId),
                search: searchParams.toString(),
              });
            }}
          >
            <Icons name="IconPencil" fill="transparent" />
            Editar grupo
          </Option>
        ),
      },
      {
        key: "delete",
        children: (
          <Option onClick={() => setDeactivateModalIsOpen(true)}>
            <Icons name="IconTrash" fill="transparent" color="#C96C01" />
            <span style={{ color: "#C96C01" }}>Excluir grupo</span>
          </Option>
        ),
      },
    ];
  }, [groupId, active]);

  useEffect(() => {
    if (deletionIsLoading)
      dispatchToast({
        type: "warning",
        content: "Excluindo grupo...",
      });
  }, [deletionIsLoading]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 0, horizontal: 30 }}
      >
        <StyledIcon name="IconDotsVertical" size={20} />
      </Menu>
      <ModalConfirmAction
        isOpen={deactivateModalIsOpen}
        loading={deletionIsLoading}
        title="Tem certeza que deseja excluir este grupo?"
        description="As pessoas deste grupo ficarão sem atribuição e todas as configurações realizadas serão perdidas."
        confirmWord="excluir"
        submitText={
          <>
            <span>Confirmar exclusão</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setDeactivateModalIsOpen(false)}
        onSubmit={async () => {
          setDeactivateModalIsOpen(false);
          deleteGroup({
            groupId: groupId,
          });
        }}
      />
    </>
  );
};
