import { useEffect, useMemo, useState } from "react";

import { Container } from "./styled";
import { TableFilters } from "./TableFilters";
import { TableGrid } from "./TableGrid";
import { TableHeader } from "./TableHeader";
import { TableSearch } from "./TableSearch";
import { useEmployeeSearch, useFindEmployeesByGroupId } from "@/hooks";

export const Table = ({
  loading,
  groupId,
  groupName,
  groupType,
  queryRule,
}: {
  loading: boolean;
  groupId: string;
  groupName: string;
  groupType: "DYNAMIC" | "STATIC";
  queryRule: any;
}) => {
  const [search, setSearch] = useState<string>("");
  const [employees, setEmployees] = useState<any[]>();
  const { data: employeeSearchResult, fetch } = useEmployeeSearch();
  const { data: findEmployeesByGroupIdResult } = useFindEmployeesByGroupId({
    groupId,
    limit: 25,
    page: 1,
  });

  useEffect(() => {
    fetch({ queryRule });
  }, [queryRule]);

  useEffect(() => {
    if (groupType === "DYNAMIC") setEmployees(employeeSearchResult?.employees);
    else setEmployees(findEmployeesByGroupIdResult?.records);
  }, [employeeSearchResult, findEmployeesByGroupIdResult]);

  const filteredEmployees = useMemo(() => {
    return employees?.filter((e) =>
      e.name!.toLowerCase().includes(search.toLowerCase()),
    );
  }, [employees, search]);

  return (
    <Container>
      <TableHeader totalCount={employees?.length || 0} />
      <TableSearch
        onChange={(e) => setSearch(e)}
        disabled={!!!employees?.length}
      />
      <TableFilters
        groupId={groupId}
        groupName={groupName}
        disabled={!groupId}
        groupType={groupType}
      />
      <TableGrid
        data={filteredEmployees as any}
        pageSize={10}
        groupId={groupId}
        groupName={groupName}
        groupType={groupType}
      />
    </Container>
  );
};
