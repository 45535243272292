import { useEffect, useMemo, useState } from "react";
import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { ModalConfirmAction } from "@Components/ModalConfirmAction";
import dispatchToast from "@Utils/dispatchToast";
import { Option } from "./styled";
import { IEmployeeOptions, TOption } from "./types";
import { useRemoveEmployeeFromGroup } from "@/hooks";

interface Member {
  _id: string;
  groups: {
    _id: string;
    name: string;
  }[];
}

export const EmployeeOptions = ({
  employeeId,
  groupId,
}: IEmployeeOptions) => {
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const { removeEmployeeFromGroup, isLoading } = useRemoveEmployeeFromGroup();

  const options: TOption[] = useMemo(() => {
    let opt: TOption[] = [
      {
        key: "profile",
        children: (
          <Option onClick={() => setConfirmRemoveModal(true)}>
            <Icons name="IconUserOff" fill="transparent" />
            Desvincular do grupo
          </Option>
        ),
      },
    ];

    return opt;
  }, [employeeId]);

  useEffect(() => {
    if (isLoading)
      dispatchToast({
        type: "warning",
        content: "Devinculando pessoa do grupo...",
      });
  }, [isLoading]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 0, horizontal: -220 }}
      >
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </Menu>
      <ModalConfirmAction
        isOpen={confirmRemoveModal}
        loading={isLoading}
        title="Tem certeza que deseja desvincular essa pessoa?"
        description="A pessoa selecionada ficará sem atribuição de grupo e todas as configurações realizadas serão perdidas."
        confirmWord="desvincular"
        submitText={
          <>
            <span>Confirmar</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setConfirmRemoveModal(false)}
        onSubmit={async () => {
          setConfirmRemoveModal(false);
          removeEmployeeFromGroup({ groupId, employeeId });
        }}
      />
    </>
  );
};
