import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  IconButton,
  Icons,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes";

import { Container, StyledBreadcrumbs } from "./styled";

export const Header = () => {
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const groupName = searchParams.get("groupName");

  return (
    <Container>
      <IconButton
        variant={"line"}
        size={"small"}
        icon="IconArrowLeft"
        onClick={() => navigate(routes.pageGroups())}
      />
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton
            variant="neutral"
            onClick={() => {
              searchParams.set("groupName", groupName as string);
              navigate({
                pathname: routes.pageEditGroup(groupId as string),
                search: searchParams.toString(),
              });
            }}
          >
            ...
          </LinkButton>,
          <LinkButton variant="neutral">Adicionar integrantes</LinkButton>,
        ]}
      />
    </Container>
  );
};
