import { trpc } from "@/api/client";

export const useRemoveEmployeeFromDepartment = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate } = trpc.removeEmployeeFromDepartment.useMutation({
    onSuccess: () => {
      utils.getDepartmentEmployees.invalidate();
    },
  });

  return { removeEmployeeFromDepartment: mutate, isLoading };
};
