import { useEmployeeSearch, useFindEmployeesByGroupId } from "@/hooks";
import { CardOptions } from "../CardOptions";
import { Card, Title, Wrapper } from "./styled";
import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";

export type BaseTabCardType = {
  key: string;
  title: string;
  queryRule?: any;
  groupType?: string;
};

export interface TabCardInterface extends BaseTabCardType {
  id: string;
  isSelected: boolean;
  setSelectedTab: (key: string) => void;
}

export const TabCard = ({
  id,
  isSelected,
  title,
  setSelectedTab,
  queryRule,
  groupType,
}: TabCardInterface) => {
  const [total, setTotal] = useState<number | undefined>();
  const { data: employees } = useFindEmployeesByGroupId({
    groupId: id,
    limit: 25,
    page: 1,
  });
  const { data, fetch } = useEmployeeSearch();
  useEffect(() => {
    if (queryRule) fetch({ queryRule });
  }, [queryRule]);

  useEffect(() => {
    setTotal(data?.employees.length);
  }, [data]);

  return (
    <Card key={id} selected={isSelected} onClick={() => setSelectedTab(id)}>
      <Title variant="body3" tag="span" selected={isSelected}>
        {title}
      </Title>
      <Wrapper>
        {employees?.metadata && groupType === "STATIC" && (
          <Tag variant={isSelected ? "gray" : "primary"} as={"span"}>
            {`${employees?.metadata.totalCount}`}
          </Tag>
        )}
        {!!total && groupType === "DYNAMIC" && (
          <Tag variant={isSelected ? "gray" : "primary"} as={"span"}>
            {`${total}`}
          </Tag>
        )}
        <CardOptions groupId={id} active={false} groupName={title} />
      </Wrapper>
    </Card>
  );
};
