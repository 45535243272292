import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";

export const Footer = ({
  onConfirm,
  loading,
}: {
  onConfirm: () => void;
  loading: boolean;
}) => {
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const groupName = searchParams.get("groupName");

  const navigate = useNavigate();

  return (
    <Container>
      <CancelButton
        variant="neutral"
        onClick={() => navigate(routes.pageGroups())}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          onClick={() => {
            searchParams.set("groupName", groupName as string);
            navigate({
              pathname: routes.pageEditGroup(groupId as string),
              search: searchParams.toString(),
            });
          }}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          loading={loading}
          onClick={onConfirm}
        >
          Confirmar <Icons name="IconCheck" fill="transparent" />
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
