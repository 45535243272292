import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";
import { CreateDepartmentRequestBody } from "bff/src/services/company-management.service";
import { useCallback } from "react";

export const useCreateDepartment = () => {
  const { isLoading, mutate, data, mutateAsync } =
    trpc.createDepartment.useMutation();
  const createDepartment = useCallback(
    (input: CreateDepartmentRequestBody) => {
      mutate(input, {
        onError: (error) => {
          const errorCode = error.data?.code;
          if (errorCode === "CONFLICT") {
            dispatchToast({
              content:
                "Departamento com mesmo nome já existe. Por favor atribua outro nome.",
              type: "error",
            });
          } else {
            dispatchToast({
              content: "Erro ao criar departamento.",
              type: "error",
            });
          }
        },
        onSuccess: () => {
          dispatchToast({
            content: "Departamento criado com sucesso",
            type: "success",
          });
        },
      });
    },
    [mutate],
  );

  return { createDepartment, isLoading, createdDepartment: data, mutateAsync };
};
