import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useCreateGroup = () => {
  const { mutate, mutateAsync, isLoading } = trpc.createGroup.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: "Grupo criado com sucesso!",
      });
    },
    onError: (e) => {
      const errorCode = e.data?.code;
      if (errorCode === "CONFLICT") {
        dispatchToast({
          content:
            "Grupo com mesmo nome já existe. Por favor atribua outro nome.",
          type: "error",
        });
      } else {
        dispatchToast({
          type: "error",
          content:
            "Erro ao criar grupo! Por favor tente novamente. Caso o problema persista, entre em contato com o atendimento",
        });
      }
    },
  });

  return { createGroup: mutate, createGroupAsync: mutateAsync, isLoading };
};
