import { DEPARTMENTS_TAB_SELECTED } from "@departments/events";
import { DepartmentTabCard } from "@departments/types";
import { setEventTracking } from "@Utils/eventTracking";

import { CardOptions } from "../CardOptions";

import { Card, Title } from "./styled";

export type BaseTabCardType = { key: string; title: string };

export interface TabCardInterface extends BaseTabCardType {
  id: string;
  department: DepartmentTabCard;
  isSelected: boolean;
  setSelectedTab: (departmentId: string) => void;
}

export const TabCard = ({
  id,
  isSelected,
  title,
  setSelectedTab,
  department,
}: TabCardInterface) => {
  return (
    <Card
      key={id}
      selected={isSelected}
      onClick={() => {
        setSelectedTab(id);
        setEventTracking(DEPARTMENTS_TAB_SELECTED, {
          department_id: id,
          department_name: title,
        });
      }}
    >
      <Title variant="body3" tag="span" selected={isSelected}>
        {title}
      </Title>
      <CardOptions department={department} />
    </Card>
  );
};
