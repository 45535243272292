import { SelectedList, SelectedListHeader } from "./index";
import { Container } from "./styled";

export function SelectedListArea() {
  return (
    <Container>
      <SelectedListHeader />
      <SelectedList />
    </Container>
  );
}
