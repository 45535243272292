import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons, Menu, MenuOptionType } from "@flash-tecnologia/hros-web-ui-v2";

import { ModalConfirmAction } from "@/components";
import { useDeactivateDepartment } from "@/hooks";
import { DepartmentTabCard } from "@/features/departments/types";
import { routes } from "@/routes";

import { Option, OptionSpan, StyledIcon } from "./styled";

type CardOptionProps = {
  department: DepartmentTabCard;
};

export const CardOptions = ({ department }: CardOptionProps) => {
  const navigate = useNavigate();
  const { id: departmentId, name, description } = department;

  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);

  const { deactivateDepartment, isLoading } = useDeactivateDepartment();

  const handleSubmit = () => {
    deactivateDepartment({
      departmentId,
    });
    setDeactivateModalIsOpen(false);
  };

  const options: MenuOptionType[] = useMemo(() => {
    return [
      {
        key: "add",
        children: (
          <Option
            onClick={() =>
              navigate(routes.departmentEmployeesEdit(departmentId))
            }
          >
            <Icons name="IconClick" fill="transparent" />
            Atribuir integrantes por plataforma
          </Option>
        ),
      },
      {
        key: "edit",
        children: (
          <Option onClick={() => navigate(routes.departmentEdit(departmentId))}>
            <Icons name="IconPencil" fill="transparent" />
            Editar departamento
          </Option>
        ),
      },
      {
        key: "delete",
        children: (
          <Option onClick={() => setDeactivateModalIsOpen(true)}>
            <Icons
              name="IconTrash"
              fill="transparent"
              color="var(--color-feedback-error-dark1)"
            />
            <OptionSpan>Excluir departamento</OptionSpan>
          </Option>
        ),
      },
    ];
  }, [departmentId, navigate]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 0, horizontal: 30 }}
      >
        <StyledIcon name="IconDotsVertical" size={20} />
      </Menu>

      <ModalConfirmAction
        isOpen={deactivateModalIsOpen}
        loading={isLoading}
        title="Tem certeza que deseja excluir este departamento?"
        description="As pessoas deste departamento ficarão sem atribuição e todas as configurações realizadas serão perdidas."
        confirmWord="excluir"
        submitText={
          <>
            <span>Confirmar exclusão</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setDeactivateModalIsOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};
