import { ReactNode, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  EmptyState,
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Employee } from "@features/departments/types";
import { routes } from "src/routes";

import { TableSkeleton } from "../table-skeleton";

import { EmployeeOptions } from "./components/EmployeeOptions";
import {
  DataGridWrapper,
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
} from "./styled";

type Props = {
  data: Record<string, ReactNode>[];
  pageSize: number;
  departmentId: string;
  loading: boolean;
};

export const TableGrid = ({ data, pageSize, departmentId, loading }: Props) => {
  const navigate = useNavigate();
  const tableRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (cellProps: any) => {
          const email = cellProps?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={cellProps?.cell?.row?.original?.name}
                source={cellProps?.cell?.row?.original?.profilePicture?.source}
              />
              <FirstColumnCellData>
                <Typography variant="body4">{cellProps.value}</Typography>
                {email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        Header: "CPF",
        accessor: "cpf",
      },
      {
        Header: "Telefone",
        accessor: "phone",
      },
      {
        Header: "Ações",
        Cell: (cellProps: any) => (
          <EmployeeOptions
            employeeId={cellProps?.cell?.row?.original?.id}
            active={cellProps?.cell?.row?.original?.active}
            departmentId={departmentId}
          />
        ),
        sticky: "right",
        disableSortBy: true,
      },
    ],
    [departmentId],
  );

  return (
    <>
      {loading && <TableSkeleton />}

      {!loading && (
        <DataGridWrapper ref={tableRef}>
          {!!data?.length && (
            <DataGrid<keyof Employee>
              hasPagination={false}
              columns={columns}
              data={data || []}
              initialState={{ pageSize: Number(pageSize) }}
            />
          )}

          {!data?.length && (
            <EmptyState
              buttonText="Atribuir integrantes"
              description="Você ainda não adicionou ninguém a esse departamento"
              buttonProps={{
                size: "medium",
                onClick: () => {
                  navigate(routes.departmentEmployeesEdit(departmentId));
                },
              }}
            />
          )}
        </DataGridWrapper>
      )}
    </>
  );
};
