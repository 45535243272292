import { trpc } from "@/api/client";
import { dispatchToast } from "@/utils";

export const useRemoveEmployeeFromGroup = () => {
  const utils = trpc.useContext();
  const { mutate, isLoading } = trpc.removeEmployeeFromGroup.useMutation({
    onError: () => {
      dispatchToast({
        content: "Erro ao remover colaborador do grupo",
        type: "error",
      });
    },
    onSuccess: () => {
      utils.findEmployeeByGroupId.invalidate();
    },
  });
  return { removeEmployeeFromGroup: mutate, isLoading };
};
